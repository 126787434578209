import Vue from "vue";
import VueRouter from "vue-router";
//import index from "../views/index.vue";
//import home from "../views/Home.vue";
import scheme from "@/views/scheme.vue";
import about from "@/views/about.vue";
import customer from "@/views/customer.vue";
import process from "@/views/process.vue";
import news from "@/views/news.vue";
import concat from '@/views/concat.vue';
import newsDet from '@/views/newsDet.vue';

import indexnew from "../views/v2/indexnew.vue";
import casesv2 from "../views/v2/casesv2.vue";
import aboutv2 from "../views/v2/aboutv2.vue";
import concatv2 from "../views/v2/concatv2.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "indexnew",
		component: indexnew,
		meta: {
			title: "深圳市乔木影视传媒有限公司",
		},
	},
	{
		path: "/casesv2",
		name: "casesv2",
		component: casesv2,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 影片案例",
		},
	},
	{
		path: "/aboutv2",
		name: "aboutv2",
		component: aboutv2,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 合作流程",
		},
	},
	{
		path: "/concatv2",
		name: "concatv2",
		component: concatv2,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 联系我们",
		},
	},
	{
		path: "/scheme",
		name: "scheme",
		component: scheme,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 解决方案",
		},
	},
	{
		path: "/about",
		name: "about",
		component: about,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 企业历程",
		},
	},
	{
		path: "/customer",
		name: "customer",
		component: customer,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 合作客户",
		},
	},
	{
		path: "/process",
		name: "process",
		component: process,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 合作流程",
		},
	},
	{
		path: "/news",
		name: "news",
		component: news,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 新闻资讯",
		},
	},
	{
		path: "/concat",
		name: "concat",
		component: concat,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 联系我们",
		},
	},
	{
		path: "/newsDet",
		name: "newsDet",
		component: newsDet,
		meta: {
			title: "深圳市乔木影视传媒有限公司 -- 新闻资讯",
		},
	}
];
// title

const router = new VueRouter({
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});
