<template>
  <div class="page">
      <!-- 头部 -->
      <v-header @jumpTo="jump" id="vheader"></v-header>

      <div class="main">
		<img :src="caseStr" alt="" v-if="false">
		<div class="case-section">
			<div class="case-item-left">
				<div v-for="(item, index) in caseDataList" :key="index" class="case-item" @click="()=>chooseItem(item)">
					<img class="case-item-img" v-if="!item.selected" :src="item.pic"/>
					<img class="case-item-img" v-if="item.selected" :src="item.selectedPic"/>
				</div>
			</div>
			<div class="case-item-right">
				<img class="case-item-right-img" :src="selectItem.showPic"/>
				<img src="https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/play.jpg" class="case-item-right-play-img" @click="playVideo(selectItem.videoUrl)" />
			</div>
		</div>
	</div>

		<!-- 视频 -->
		<el-dialog
			title="视频预览"
			:visible.sync="dialogPlay"
			width="60%"
			@close="closeDialog"
		>
			<video
				:src="videoUrl"
				controls
				autoplay
				class="video"
				ref="dialogVideo"
				width="100%"
			></video>
		</el-dialog>


  </div>
</template>

<script>

import {data} from "../../assets/js/datav2.js";

import VHeader from "../../components/headerv2.vue";

export default {
    name: "casesv2",
    components: {
        VHeader
    },
    data()
    {
        return {
            caseStr: data.caseStr,
						caseDataList: data.caseData,
						selectItem: {},
						videoUrl: "",
						dialogPlay: false
        }
    },
	created() {
		this.selectItem = this.caseDataList[0];
		this.selectItem.selected = true;
	},
    methods: {
        jump(url){
            this.$router.push(url);
        },
		chooseItem(item){
			for(let tItem of this.caseDataList){
				if(tItem.id == item.id ){
					tItem.selected = true;
					this.selectItem = tItem;
				} else {
					tItem.selected = false;
				}
			}
		},
		playVideo(url){
			this.dialogPlay = true;
			this.videoUrl = url;
		},
		closeDialog() {
			this.dialogPlay = false;
			this.videoUrl = ""; //清空数据 关闭视频播放
		}
	}
}
</script>

<style scoped>
@import "../../../src/assets/css/homev2.css";
</style>

<style scoped>
.case-section {
	width: 87.5rem;
	height: 54.375rem;
	background: url("https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/case/caseback.png") 100% no-repeat;
	background-size: 100% 100%;
	display: flex;
	flex-direction: row;
}
.case-item-left {
	display: flex;
	flex-direction: column;
	width: 23rem;
	padding-top: 5.625rem;
}
.case-item {
	width: 23rem;
	height: 7.75rem;
	cursor: pointer;
}
.case-item-img {
	width: 23rem;
	height: 7.75rem;
}

.case-item-right {
	margin-left: 3.5rem;
	padding-top: 1rem;
}

.case-item-right-img {
	width: 58.75rem;
	height: 33.0625rem;
}
.case-item-right-play-img {
	width:7.5rem;
	height:7.5rem;
	top: -23rem;
	position: relative;
	left: 28rem;
	cursor: pointer;
}
</style>
